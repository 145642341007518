<template>
  <div class="columns">
    <div class="column">
      <p class="has-text-weight-bold has-text-right" :class="sizeClass">{{ label }}:</p>
    </div>
    <div class="column">
      <p :class="sizeClass">{{ field }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, required: true },
    field: { type: [String, Number], required: true },
    size: { type: String, default: "3" }
  },
  computed: {
    sizeClass() {
      return "is-size-" + this.size;
    }
  }
};
</script>
