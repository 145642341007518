const CohenEnum = {
  Never: 1,
  LessOnceWeek: 2,
  OneTwoPerWeek: 3,
  SeveralPerWeek: 4,
  OneTwoPerDay: 5,
  SeveralPerDay: 6,
  SeveralPerHour: 7
};

const CohenLookup = [
  "",
  "1 - Never",
  "2 - Less than Once per Week",
  "3 - Once or Twice per Week",
  "4 - Several Times per Week",
  "5 - Once or Twice per Day",
  "6 - Several Times per Day",
  "7 - Several Times per Hour"
];

const CohenLookupShort = [
  "",
  "Never",
  "< 1 per Week",
  "1-2 per Week",
  "Several per Week",
  "1-2 per Day",
  "Several per Day",
  "Several per Hour"
];

const colour = [
  "",
  "has-text-success",
  "has-text-success",
  "has-text-warning",
  "has-text-warning",
  "has-text-high-warning",
  "has-text-high-warning",
  "has-text-danger"
];

export default {
  Enum: CohenEnum,
  SelectLookup: [
    { id: CohenEnum.Never, value: CohenLookup[CohenEnum.Never] },
    { id: CohenEnum.LessOnceWeek, value: CohenLookup[CohenEnum.LessOnceWeek] },
    { id: CohenEnum.OneTwoPerWeek, value: CohenLookup[CohenEnum.OneTwoPerWeek] },
    { id: CohenEnum.SeveralPerWeek, value: CohenLookup[CohenEnum.SeveralPerWeek] },
    { id: CohenEnum.OneTwoPerDay, value: CohenLookup[CohenEnum.OneTwoPerDay] },
    { id: CohenEnum.SeveralPerDay, value: CohenLookup[CohenEnum.SeveralPerDay] },
    { id: CohenEnum.SeveralPerHour, value: CohenLookup[CohenEnum.SeveralPerHour] }
  ],
  Lookup: CohenLookup,
  LookupShort: CohenLookupShort,
  Colour: colour,
  Icon: ["", "check", "check", "alert-outline", "alert-outline", "alert", "alert", "alert-circle"]
};
