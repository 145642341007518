import Risk from "@/enums/risk";
import Cohen from "@/enums/cohen";
import Barthel from "@/enums/barthel";
import Severe from "@/enums/severe";
import { GetSelectLookup } from "./common";

const AssessmentTypeEnum = {
  Waterlow: 1,
  Must: 2,
  Cannard: 3,
  CohenMansfield: 4,
  Generic: 5,
  IntimateCare: 6,
  Barthel: 7,
  MMSE: 8,
  OralCavity: 9,
  ManualHandling: 10,
  Activities: 11,
  PreAdmission: 12
};

const RiskTypeEnum = {
  None: 0,
  Standard4: 1,
  Standard3: 2,
  Cohen: 3,
  Barthel: 4,
  Severe: 5
};

const AssessmentLookUp = [
  "",
  "Waterlow",
  "Must",
  "Cannard",
  "Cohen-Mansfield",
  "Risk Assessment",
  "My Intimate Care",
  "Barthel",
  "MMSE",
  "Oral Cavity",
  "Manual Handling",
  "Activities",
  "Pre-Admission"
];
const AssessmentUrl = [
  "",
  "waterlow",
  "must",
  "cannard",
  "cohenmansfield",
  "generic",
  "intimateCare",
  "barthel",
  "mmse",
  "oralCavity",
  "manualHandling",
  "activities",
  "preadmission"
];
const AssessmentRiskType = [
  null,
  RiskTypeEnum.Standard4,
  RiskTypeEnum.Standard3,
  RiskTypeEnum.Standard4,
  RiskTypeEnum.Cohen,
  RiskTypeEnum.Standard3,
  RiskTypeEnum.None,
  RiskTypeEnum.Barthel,
  RiskTypeEnum.Severe,
  RiskTypeEnum.Severe,
  RiskTypeEnum.None,
  RiskTypeEnum.None,
  RiskTypeEnum.None
];
const Component = [
  "",
  "Waterlow",
  "Must",
  "Cannard",
  "Cohen",
  "Risk",
  "IntimateCare",
  "Barthel",
  "MMSE",
  "OralCavity",
  "ManualHandling",
  "Activities",
  "PreAdmission"
];
const Icon = [
  "",
  "bed",
  "food-fork-drink",
  "traffic-cone",
  "account-alert-outline",
  "shield",
  "shield-account",
  "walk",
  "brain",
  "toothbrush-paste",
  "transit-transfer",
  "",
  "account-check"
];
const CustomIcon = ["", "", "", "", "", "", "", "", "", "", "", "running", ""];

function riskRatingDesc(riskType, rating) {
  switch (riskType) {
    case RiskTypeEnum.Cohen:
      return Cohen.Lookup[rating];
    case RiskTypeEnum.Barthel:
      return Barthel.Lookup[rating];
    case RiskTypeEnum.Severe:
      return Severe.Lookup[rating];
    case RiskTypeEnum.None:
      return "";
    default:
      return Risk.Lookup[rating];
  }
}
function riskColour(riskType, rating) {
  switch (riskType) {
    case RiskTypeEnum.Cohen:
      return Cohen.Colour[rating];
    case RiskTypeEnum.Barthel:
      return Barthel.Colour[rating];
    case RiskTypeEnum.Severe:
      return Severe.Colour[rating];
    case RiskTypeEnum.Standard3:
      return Risk.Colour3[rating];
    case RiskTypeEnum.Standard4:
      return Risk.Colour4[rating];
    default:
      return "";
  }
}

export default {
  Enum: AssessmentTypeEnum,
  RiskTypeEnum: RiskTypeEnum,
  SelectLookup: GetSelectLookup(AssessmentTypeEnum, AssessmentLookUp),
  Lookup: AssessmentLookUp,
  Url: AssessmentUrl,
  RiskType: AssessmentRiskType,
  Component: Component,
  Icon: Icon,
  CustomIcon: CustomIcon,
  All: [
    {
      Type: AssessmentTypeEnum.Waterlow,
      Title: AssessmentLookUp[AssessmentTypeEnum.Waterlow],
      Icon: Icon[AssessmentTypeEnum.Waterlow],
      Path: AssessmentUrl[AssessmentTypeEnum.Waterlow]
    },
    {
      Type: AssessmentTypeEnum.Must,
      Title: AssessmentLookUp[AssessmentTypeEnum.Must],
      Icon: Icon[AssessmentTypeEnum.Must],
      Path: AssessmentUrl[AssessmentTypeEnum.Must]
    },
    {
      Type: AssessmentTypeEnum.Cannard,
      Title: AssessmentLookUp[AssessmentTypeEnum.Cannard],
      Icon: Icon[AssessmentTypeEnum.Cannard],
      Path: AssessmentUrl[AssessmentTypeEnum.Cannard]
    },
    {
      Type: AssessmentTypeEnum.CohenMansfield,
      Title: AssessmentLookUp[AssessmentTypeEnum.CohenMansfield],
      Icon: Icon[AssessmentTypeEnum.CohenMansfield],
      Path: AssessmentUrl[AssessmentTypeEnum.CohenMansfield]
    },
    {
      Type: AssessmentTypeEnum.IntimateCare,
      Title: AssessmentLookUp[AssessmentTypeEnum.IntimateCare],
      Icon: Icon[AssessmentTypeEnum.IntimateCare],
      Path: AssessmentUrl[AssessmentTypeEnum.IntimateCare]
    },
    {
      Type: AssessmentTypeEnum.Barthel,
      Title: AssessmentLookUp[AssessmentTypeEnum.Barthel],
      Icon: Icon[AssessmentTypeEnum.Barthel],
      Path: AssessmentUrl[AssessmentTypeEnum.Barthel]
    },
    {
      Type: AssessmentTypeEnum.MMSE,
      Title: AssessmentLookUp[AssessmentTypeEnum.MMSE],
      Icon: Icon[AssessmentTypeEnum.MMSE],
      Path: AssessmentUrl[AssessmentTypeEnum.MMSE]
    },
    {
      Type: AssessmentTypeEnum.OralCavity,
      Title: AssessmentLookUp[AssessmentTypeEnum.OralCavity],
      Icon: Icon[AssessmentTypeEnum.OralCavity],
      Path: AssessmentUrl[AssessmentTypeEnum.OralCavity]
    },
    {
      Type: AssessmentTypeEnum.ManualHandling,
      Title: AssessmentLookUp[AssessmentTypeEnum.ManualHandling],
      Icon: Icon[AssessmentTypeEnum.ManualHandling],
      Path: AssessmentUrl[AssessmentTypeEnum.ManualHandling]
    },
    {
      Type: AssessmentTypeEnum.Activities,
      Title: AssessmentLookUp[AssessmentTypeEnum.Activities],
      CustomIcon: CustomIcon[AssessmentTypeEnum.Activities],
      Path: AssessmentUrl[AssessmentTypeEnum.Activities]
    },
    {
      Type: AssessmentTypeEnum.PreAdmission,
      Title: AssessmentLookUp[AssessmentTypeEnum.PreAdmission],
      Icon: Icon[AssessmentTypeEnum.PreAdmission],
      Path: AssessmentUrl[AssessmentTypeEnum.PreAdmission]
    }
  ],
  assessmentRatingDesc(assessmentType, rating) {
    return riskRatingDesc(AssessmentRiskType[assessmentType], rating);
  },
  riskRatingDesc: riskRatingDesc,
  assessmentColor(assessmentType, rating, selected) {
    return riskColour(AssessmentRiskType[assessmentType], rating) + (selected ? "-selected" : "");
  },
  riskColour: riskColour,
  riskIcon(riskType, rating) {
    switch (riskType) {
      case RiskTypeEnum.Cohen:
        return Cohen.Icon[rating];
      case RiskTypeEnum.Barthel:
        return Barthel.Icon[rating];
      case RiskTypeEnum.Severe:
        return Severe.Icon[rating];
      case RiskTypeEnum.Standard4:
        return Risk.Icon4[rating];
      case RiskTypeEnum.Standard3:
        return Risk.Icon3[rating];
      default:
        return "";
    }
  }
};
