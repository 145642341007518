import { GetSelectLookup } from "./common";

const BarthelLevelEnum = {
  Max: 0,
  High: 1,
  Medium: 2,
  Low: 3,
  Independent: 4
};

const BarthelLookup = ["Max Dependency", "High Dependency", "Medium Dependency", "Low Dependency", "Independent"];

const colour = ["has-text-danger", "has-text-danger", "has-text-high-warning", "has-text-warning", "has-text-success"];

function selectLookup(array) {
  return array.map((v, i) => ({ id: i, value: v }));
}

export default {
  Enum: BarthelLevelEnum,
  SelectLookup: GetSelectLookup(BarthelLevelEnum, BarthelLookup),
  Lookup: BarthelLookup,
  Colour: colour,
  Icon: ["alert-circle", "alert", "alert", "alert-outline", "check"],
  MobilityLookup: selectLookup([
    "Immobile or < 50 yards",
    "Wheelchair independent, incl corners, > 50 yards",
    "Walks with help of one person (verbal or physical) > 50 yards",
    "lndependent (but may use aid for example, stick) > 50 yards"
  ]),
  TransfersLookup: selectLookup([
    "Unable, no sitting balance",
    "Major help (one or two people, physical) can sit",
    "Minor help (verbal or physical)",
    "Independent"
  ]),
  StairsLookup: selectLookup(["Unable", "Needs help (verbal or physical, carrying aid)", "lndependent"]),
  BowelsLookup: selectLookup(["Incontinent(or needs to be given enemas)", "Occasional accident", "Continent"]),
  BladderLookup: selectLookup(["lncontinent or catheterized and unable to manage alone", "Occasional accident", "Continent"]),
  ToiletLookup: selectLookup(["Dependent", "Needs some help but can do something alone", "Independent (on and off, dressing, wiping)"]),
  BathingLookup: selectLookup(["Dependent", "lndependent (or in shower)"]),
  GroomingLookup: selectLookup(["Needs help with personal care", "Independent face/hair/teeth/shaving (implements provided)"]),
  DressingLookup: selectLookup(["Dependent", "Needs help but can do about half unaided", "Independent (incl buttons, zips, laces, etc)"]),
  FeedingLookup: selectLookup(["Unable", " Needs help cutting, spreading butter, etc or requires modified diet", "lndependent"])
};
