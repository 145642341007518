import routeParams from "@/common/routeParams";

import AssessmentType from "@/enums/assessmentType.js";

const routes = [];
for (var v in AssessmentType.Enum) {
  const t = AssessmentType.Enum[v];
  routes.push({
    meta: {
      title: "New " + AssessmentType.Lookup[t],
      showResidentOnPage: true
    },
    path: "/" + AssessmentType.Url[t] + "/new",
    name: AssessmentType.Url[t] + ".new",
    component: () => import(/* webpackChunkName: "[request]" */ "../views/assessments/" + AssessmentType.Component[t] + ".vue")
  });
  routes.push({
    meta: {
      title: "Edit " + AssessmentType.Lookup[t],
      showResidentOnPage: true
    },
    path: "/" + AssessmentType.Url[t] + "/:id",
    name: AssessmentType.Url[t] + ".edit",
    component: () => import(/* webpackChunkName: "[request]" */ "../views/assessments/" + AssessmentType.Component[t] + ".vue"),
    props: routeParams.idAsInt
  });
}

export default [
  {
    meta: {
      title: "Assessments2",
      showResidentOnPage: true,
      hideFooter: true
    },
    name: "assessments2",
    path: "/assessments2",
    component: () => import(/* webpackChunkName: "Assessments" */ "../views/assessments/Assessments.vue")
  },
  {
    meta: {
      title: "Assessments",
      showResidentOnPage: true,
      hideFooter: true
    },
    name: "assessments",
    path: "/assessments",
    component: () => import(/* webpackChunkName: "Assessments" */ "../views/assessments/AssessmentCarePlans.vue")
  },
  {
    meta: {
      title: "Actions",
      showResidentOnPage: true,
      hideFooter: true
    },
    name: "actions",
    path: "/actions/:selResidentId?",
    component: () => import(/* webpackChunkName: "Actions" */ "../views/ActionsNotes.vue"),
    props: routeParams.selResidentIdInt
  },
  {
    meta: {
      title: "Observations",
      showResidentOnPage: true
    },
    name: "observations",
    path: "/observations",
    component: () => import(/* webpackChunkName: "Observations" */ "../views/Observations.vue"),
    props: routeParams.selResidentIdInt
  },
  {
    meta: {
      title: "Wounds",
      showResidentOnPage: true
    },
    name: "wounds",
    path: "/wounds",
    component: () => import(/* webpackChunkName: "Wounds" */ "../views/Wounds.vue"),
    props: routeParams.selResidentIdInt
  },
  {
    meta: {
      title: "Wounds",
      showResidentOnPage: true
    },
    name: "wounds2",
    path: "/wounds2",
    component: () => import(/* webpackChunkName: "Wounds" */ "../views/Wounds2.vue"),
    props: routeParams.selResidentIdInt
  },
  ...routes
];
