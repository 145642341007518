export default {
  namespaced: true,
  state() {
    return {
      search: {
        locationId: null,
        title: null
      }
    };
  },
  mutations: {
    setSearch(state, search) {
      state.search = search;
    }
  }
};
