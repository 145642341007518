import api from "@/api.js";
import alerts from "@/common/alerts";

export default {
  namespaced: true,
  state() {
    return {
      roles: [],
      gotRoles: false,
      gettingRoles: false
    };
  },
  mutations: {
    setRoles(state, roles) {
      state.roles = roles;
    },
    gettingRoles(state, getting) {
      state.gettingRoles = getting;
    },
    gotRoles(state, got) {
      state.gotRoles = got;
    }
  },
  actions: {
    async getRoles(context) {
      if (context.state.gotRoles || context.state.gettingRoles) return;
      context.commit("gettingRoles", true);

      await api
        .get("roles")
        .then(r => {
          context.commit("setRoles", r.data);
          context.commit("gotRoles", true);
        })
        .catch(e => alerts.showErrorAlert(e, "Failed to load Roles"))
        .finally(() => context.commit("gettingRoles", false));
    }
  },
  getters: {
    roles(state) {
      return state.roles;
    },
    roleLevel(state) {
      return roleId => {
        return state.roles.find(r => r.id === roleId)?.level;
      };
    }
  }
};
