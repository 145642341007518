<template>
  <div class="modal-card">
    <header class="modal-card-head is-danger">
      <p class="modal-card-title">
        <b-icon icon="wifi-off" />
        Network Offline
      </p>
    </header>
    <section class="modal-card-body">
      Looks like you are not connected to the internet, please check you network connection.
    </section>
  </div>
</template>

<script>
export default {
  mounted() {}
};
</script>
