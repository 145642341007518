<template>
  <div class="is-user-avatar">
    <img :src="avatarImage" />
  </div>
</template>

<script>
import Gender from "@/enums/gender";

export default {
  props: {
    avatar: {
      type: String
      // required: true,  should be require but need to fix up other places where used
    },
    gender: { type: String }
  },
  computed: {
    avatarImage() {
      // prettier-ignore
      return this.avatar
        ? this.avatar
        : this.gender === Gender.Values.Female
          ? require("@/assets/profile-female.jpg")
          : require("@/assets/profile-male.jpg");
    }
  }
};
</script>
