import PermissionsEnum from "@/enums/permissions";

export default {
  userId(state) {
    return state.userId;
  },
  token(state) {
    return state.token;
  },
  refreshToken(state) {
    return state.refreshToken;
  },
  isAuthenticated(state) {
    return !!state.token;
  },
  userName(state) {
    return state.firstName + " " + state.lastName;
  },
  firstName(state) {
    return state.firstName;
  },
  lastName(state) {
    return state.lastName;
  },
  email(state) {
    return state.email;
  },
  avatar(state) {
    return state.avatar;
  },
  avatarUrl(state) {
    return state.avatarUrl;
  },
  orgInfo(state) {
    return state.orgInfo;
  },
  orgId(state) {
    return state.orgInfo?.orgId;
  },
  userRoleLevel(state) {
    return state.roleLevel;
  },
  defaultCountryCode(state) {
    return state.orgInfo ? state.orgInfo.defaultCountryCode : null;
  },
  permissions(state) {
    return state.permissions;
  },
  hasPermission(state) {
    return function(permission) {
      if (Array.isArray(permission)) {
        return state.permissions.some(p => permission.includes(p));
      }
      return state.permissions.includes(permission);
    };
  },
  isAdmin(state) {
    return state.permissions.includes(PermissionsEnum.SystemAdmin);
  },
  defaultRoute(state) {
    if (state.permissions.includes(PermissionsEnum.KPIReports)) return "/kpireports";
    if (state.permissions.includes(PermissionsEnum.Residents)) return "/residents";
    if (state.permissions.includes(PermissionsEnum.KPIEntry)) return "/kpi";
    return "";
  }
};
