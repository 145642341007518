const Icons = {
  add: "plus-box",
  addMultiple: "plus-box-multiple",
  edit: "pencil",
  delete: "delete",
  passwordReset: "lock-reset",
  refresh: "refresh-circle",
  enquiry: "account-question",
  graph: "chart-line",
  history: "history",
  notes: "message",
  wound: "hospital-box-outline",
  obsHeight: "human-male-height",
  obsWeight: "weight-kilogram",
  obsSkin: "human-handsdown",
  obsOxygen: "gas-cylinder",
  obsPulse: "heart-pulse",
  obsConscious: "sleep",
  obsTemp: "thermometer",
  obsPupils: "eye",
  obsBowel: "paper-roll",
  obsLimbs: "arm-flex",
  left: "arrow-left-bold",
  right: "arrow-right-bold",
  bed: "bed",
  room: "door",
  statusChange: "cog-play-outline",
  locationGrouping: "map-marker",
  locationCentre: "office-building-marker-outline",
  locationWingCorridor: "select-marker"
};

const CustomIcons = {
  obsRespiration: "breath.png",
  obsRespirationInvert: "breath_invert.png",
  obsBlood: "pressure.png",
  obsBloodInvert: "pressure_invert.png",
  obsUrine: "toilet.png",
  obsUrineInvert: "toilet_invert.png",
  obsPain: "pain.png",
  obsPainInvert: "pain_invert.png",
  obsBloodSugar: "bloodsugar.png",
  obsBloodSugarInvert: "bloodsugar_invert.png",
  obsCough: "coughing.png",
  obsCoughInvert: "coughing_invert.png",
  obsComaScale: "gcs.png",
  obsComaScaleInvert: "gcs_invert.png"
};

export default {
  icons: Icons,
  created() {
    this.Icons = Icons;
    this.CustomIcons = CustomIcons;
  },

  headerIconAdd(entity) {
    return { icon: Icons.add, name: "add", title: "Add New " + entity };
  },
  headerIconAddMultiple(entities) {
    return { icon: Icons.addMultiple, name: "multiple", title: "Add Multiple " + entities };
  },
  headerIconRefresh() {
    return { icon: Icons.refresh, name: "refresh", title: "Refresh Search" };
  }
};
