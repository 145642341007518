export default {
  Enum: {
    Freq_Default_OneOff: 0,
    Freq_Default_OnceAMonth: 1,
    Freq_Default_OnceAWeek: 2,
    Freq_Default_TwiceAWeek: 3,
    Freq_Default_OnceADay: 4,
    Freq_Default_TwiceADay: 5,
    Freq_Default_ThreeTimesADay: 6,

    Obs_RecentWeightDays: 7,
    Obs_WeightLossCalc_Previous_Min: 8,
    Obs_WeightLossCalc_Previous_Max: 9,
    Obs_WeightLossCalc_Previous_Preferred: 10
  }
};
