import router from "../../../router/index.js";
import $http from "../../../api";
import LogRocket from "logrocket";

let timer;

export default {
  async login(context, payload) {
    return context.dispatch("auth", {
      ...payload,
      mode: "login"
    });
  },
  async signup(context, payload) {
    return context.dispatch("auth", {
      ...payload,
      mode: "signup"
    });
  },

  async auth(context, payload) {
    const mode = payload.mode;
    const url = mode === "signup" ? "authenticate/sign" : "authenticate/login";

    await $http
      .post(url, {
        email: payload.email,
        password: payload.password
      })
      .then(r => {
        if (!r.data.succeeded) {
          const error = new Error(r.data.message || "Failed to authenticate. Check your login data.");
          throw error;
        }

        const expiresIn = +r.data.expirySecs * 1000;
        r.data.expirationDate = new Date().getTime() + expiresIn;

        localStorage.setItem("user", JSON.stringify(r.data));
        context.commit("setUser", r.data);

        // Auto logout after new token expires
        // timer = setTimeout(function() {
        //   context.dispatch("autoLogout");
        // }, expiresIn);
      });
  },

  tryLogin(context) {
    const user = localStorage.getItem("user");
    const userData = user ? JSON.parse(user) : {};

    // set state from local storage even if not valid, so we get expired token
    if (userData.token && userData.userId) {
      context.commit("setUser", userData);

      LogRocket.identify(userData.userId, {
        name: userData.userName,
        email: userData.email
      });
    }
    // refresh will be done by the interceptors

    // Auto logout after stored token expires
    //  const expiresIn = +userData.expirationDate - new Date().getTime();
    // if (expiresIn < 0) return;
    // timer = setTimeout(function() {
    //   context.dispatch("autoLogout");
    // }, expiresIn);
  },
  logout(context) {
    localStorage.removeItem("user");
    localStorage.removeItem("resident");

    clearTimeout(timer);

    context.commit("setUser", { token: null, userId: null });
    // On logout, clear all State, using vuex-extensions
    this.reset();

    router.replace("/login");
  },
  autoLogout(context) {
    context.dispatch("logout");
  },
  async refreshToken(context) {
    const refreshToken = context.getters.refreshToken;
    if (!refreshToken) return context.dispatch("logout");
    await $http
      .post("authenticate/refreshToken", {
        accessToken: context.getters.token,
        refreshToken: refreshToken
      })
      .then(r => {
        context.commit("setTokens", r.data);

        // refresh localstorage
        const user = localStorage.getItem("user");
        if (user) {
          const userData = JSON.parse(user);
          userData.token = r.data.token;
          userData.refreshToken = r.data.refreshToken;
          localStorage.setItem("user", JSON.stringify(userData));
        }
      });
  },
  setAvatar(context, newImage) {
    context.commit("setAvatar", newImage);
    // refresh localstorage
    const user = localStorage.getItem("user");
    if (user) {
      const userData = JSON.parse(user);
      userData.avatar = newImage.fullFileName;
      userData.avatarUrl = newImage.url;
      localStorage.setItem("user", JSON.stringify(userData));
    }
  }
};
