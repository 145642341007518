import api from "@/api.js";
import alerts from "@/common/alerts";
import locationType from "@/enums/locationType.js";
// import store from "@/store/index.js";

function checkHideGrandChildOption(data) {
  // for top level without grandchildren, we remove the children from second level
  if (data) {
    if (data.noSubLocations) delete data.children;
    if (Array.isArray(data)) {
      data.forEach(child => {
        if (child.noSubLocations) delete child.children;
      });
    }
  }
}

function updateChildren(parent, children) {
  // this was used when location was bring just portion of the tree, but that wasn't working correctly
  // if (!children) return;
  // children.forEach(child => {
  //   if (!parent.children) parent.children = children;
  //   else {
  //     var match = parent.children.find(c => c.id === child.id);
  //     if (!match) {
  //       parent.children.push(child);
  //     }
  //     updateChildren(match, child.children);
  //   }
  // });

  // just update all
  parent.children = children;
}

function findLocation(locations, locationId, parent) {
  if (!locations || locations.length === 0) return null;

  // Note the process of recursively calling findlocation, then sets the parent property on the child that we can use later
  var location = locations.find(l => l.id === locationId);
  if (location) {
    if (parent) location.parent = parent;
    return location;
  }

  for (var l = 0; l < locations.length; l++) {
    location = findLocation(locations[l].children, locationId, locations[l]);
    if (location) {
      if (parent) locations[l].parent = parent;
      return location;
    }
  }
  return null;
}

function getMainLocations(locations) {
  if (!locations || locations.length === 0) return null;

  // copy the locations using ...
  var mainLocations = [];
  locations.forEach(location => {
    if (location.locationType !== locationType.Enum.WingCorridor) {
      const mainLocation = { ...location };
      if (mainLocation.locationType !== locationType.Enum.MainLocation) {
        mainLocation.children = getMainLocations(mainLocation.children);
      } else {
        delete mainLocation.children;
      }

      mainLocations.push(mainLocation);
    }
  });

  return mainLocations;
}

function addParentName(location, includeMainLocation) {
  if (!location.parent || location.locationType !== locationType.Enum.WingCorridor) return "";
  if (!includeMainLocation && location.parent.locationType === locationType.Enum.MainLocation) return "";
  return " > " + location.parent.label + addParentName(location.parent);
}

export default {
  namespaced: true,
  state() {
    return {
      locations: null,
      selectedLocationId: null,
      selectedLocationName: "",
      selectedLocationNoChildren: false
    };
  },
  mutations: {
    addLocations(state, locations) {
      state.locations = locations;
    },
    addSubLocations(state, sublocations) {
      // Had issue on inital load, when loading sublocation using expand tree without actually selecting the parent node.
      // Finding the parent within the current state resovled that.
      const parent = findLocation(state.locations, sublocations.parentNode.id);
      if (parent) parent.children = sublocations.children;
    },
    addExtraLocations(state, newData) {
      updateChildren(state.locations[0], newData[0].children);
    },
    setSelectedLocationId(state, locationId) {
      state.selectedLocationId = locationId;
    },
    setSelectedLocationName(state, locationName) {
      state.selectedLocationName = locationName;
    },
    setSelectedLocationNoChildren(state, noChildren) {
      state.selectedLocationNoChildren = noChildren;
    }
  },
  actions: {
    async getLocations(context) {
      await api
        .get("locations")
        .then(r => {
          if (r && r.data) {
            r.data.forEach(l => {
              // default first option to expand
              l.isDefaultExpanded = true;
              checkHideGrandChildOption(l);
              checkHideGrandChildOption(l.children);
            });
            context.commit("addLocations", r.data);
          }
        })
        .catch(e => alerts.showErrorAlert(e, "Failed to load locations"));
    },
    async getSubLocations(context, parentNode) {
      await api
        .get("locations/" + parentNode.id)
        .then(r => {
          console.log(r.data);
          if (r && r.data) {
            checkHideGrandChildOption(r.data);
            context.commit("addSubLocations", { parentNode: parentNode, children: r.data });
          }
        })
        .catch(e => alerts.showErrorAlert(e, "Failed to load sub locations"));
    },
    async getUnknownLocation(context, locationId) {
      await api
        .get("locations/findUnknown/" + locationId)
        .then(r => {
          if (r && r.data) {
            checkHideGrandChildOption(r.data);
            context.commit("addExtraLocations", r.data);
          }
        })
        .catch(e => alerts.showErrorAlert(e, "Failed to load sub locations"));
    },
    selectLocationId(context, locatonId) {
      context.commit("setSelectedLocationId", locatonId);
    },
    selectLocationNode(context, node) {
      context.commit("setSelectedLocationId", node.id);
      context.commit("setSelectedLocationName", node.label);
      context.commit("setSelectedLocationNoChildren", node.children === undefined);
    }
  },
  getters: {
    locations(state) {
      return state.locations;
    },
    // Return locations from give location down
    locationsFrom: state => locationId => {
      const location = findLocation(state.locations, locationId);
      if (location) return [location];
      return state.locations;
    },
    locationsMainOnly(state) {
      return getMainLocations(state.locations);
    },
    selectedLocationId(state) {
      return state.selectedLocationId;
    },
    selectedLocationName(state) {
      return state.selectedLocationName;
    },
    selectedLocationNoChildren(state) {
      return state.selectedLocationNoChildren;
    },
    locationName: state => (locationId, fallbackName, includeMainLocation, prefix) => {
      // try to find the location from the current state
      const location = findLocation(state.locations, locationId);
      if (!location) {
        // store.dispatch("locations/getUnknownLocation", locationId);
        if (fallbackName && prefix) fallbackName = prefix + fallbackName;
        return fallbackName;
      }

      var name =
        location.locationType !== locationType.Enum.MainLocation || includeMainLocation
          ? location.label + addParentName(location, includeMainLocation)
          : "";
      if (name && prefix) name = prefix + name;
      return name;
    }
  }
};
