import routeParams from "@/common/routeParams";
import PermissionsEnum from "@/enums/permissions";

export default [
  {
    meta: {
      title: "KPI",
      permission: PermissionsEnum.KPITest
    },
    name: "kpitest",
    path: "/kpitest",
    component: () => import("../views/KpiTest.vue")
  },
  {
    meta: {
      title: "KPIs",
      permission: PermissionsEnum.KPIEntry
    },
    name: "kpi",
    path: "/kpi",
    component: () => import(/* webpackChunkName: "kpientry" */ "../views/KpiList.vue")
  },
  {
    meta: {
      title: "New KPI Form",
      permission: PermissionsEnum.KPIEntry,
      hideFooter: true
    },
    name: "kpientry",
    path: "/kpientry",
    component: () => import(/* webpackChunkName: "kpientry" */ "../views/KpiEntry.vue")
  },
  {
    meta: {
      title: "Edit KPI",
      permission: PermissionsEnum.KPIEntry,
      hideFooter: true
    },
    name: "kpientry.edit",
    path: "/kpientry/:id",
    component: () => import(/* webpackChunkName: "kpientry" */ "../views/KpiEntry.vue"),
    props: routeParams.idAsInt
  },
  {
    meta: {
      title: "KPI Reports",
      permission: PermissionsEnum.KPIReports
    },
    name: "kpireports",
    path: "/kpireports",
    component: () => import(/* webpackChunkName: "kpireports" */ "../views/KpiReports.vue")
  }
];
