import { DialogProgrammatic as Dialog } from "buefy";
import store from "../store/index";
import api from "@/api.js";

export default {
  /// /////////////////////////////////
  //  Show Alert and redirect to login if 401
  //  If redirect, can use callBackOnRedirect for example to close Dialog
  /// /////////////////////////////////
  showErrorAlert(e, title, callBackOnRedirect) {
    var msg = e.message || e;
    var redirect = false;
    title = title || "An error has occurred";

    // offline, no alert, as offline dialog will show
    if (e.message === api.offlineMsg) return;

    if (e.response) {
      if (e.response.status === 401) {
        msg = "Your session has timed out, please log in again.";
        redirect = true;
      } else {
        if (e.response.status === 403) {
          msg = e.response.data?.toString() || "You do not have permission to access that resource";
        } else if (e.response.status === 404) {
          msg = e.response.data?.toString() || "Resource not found";
        } else if (e.response.data) {
          var extra = e.response.data.toString();
          var errors = e.response.data.errors;
          if (e.response.data.title) extra = e.response.data.title;
          if (errors) extra = errors[Object.keys(errors)[0]][0];
          if (msg.startsWith("Request failed with status code")) {
            msg = extra;
            extra = "";
          }
          if (extra) msg += `<br><br><i>${extra.substring(0, 400)}</i>`;
        }

        // log if not a system error, as this already logged
        if (!e.response.headers.systemerror) {
          api.post("log", { title: title, message: e.message, extra: extra }).catch();
        }
      }
    } else {
      // generic error - log
      api.post("log", { title: title, message: msg }).catch();
    }

    Dialog.alert({
      title: title,
      message: msg,
      type: "is-danger",
      hasIcon: true,
      icon: "alert"
    });

    if (redirect) {
      store.dispatch("logout");
      if (callBackOnRedirect) callBackOnRedirect();
    }
  },

  showAlert(title, msg) {
    Dialog.alert({
      title: title,
      message: msg,
      type: "is-danger",
      hasIcon: true,
      icon: "alert"
    });
  }
};
