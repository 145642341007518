export default {
  removeItem(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  },

  removeAtIndex(arr, index) {
    arr.splice(index, 1);
    return arr;
  },

  // These function return a new array based on the operation, only use if you need to keep the orginal array unchanged
  removeLastValue(thisArrary) {
    var copy = [...thisArrary];
    copy.pop();
    return copy;
  },
  removeFirstValue(thisArrary) {
    var copy = [...thisArrary];
    copy.shift();
    return copy;
  },
  addValueStart(thisArrary, newValue) {
    var copy = [...thisArrary];
    copy.unshift(newValue);
    return copy;
  },
  addValueEnd(thisArrary, newValue) {
    var copy = [...thisArrary];
    copy.push(newValue);
    return copy;
  }
};
