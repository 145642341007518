<template>
  <li v-if="item" :class="{ 'is-active': isDropdownActive }">
    <a :href="itemHref" :class="{ 'is-active': isActive, 'has-icon': !!item.icon, 'has-dropdown-icon': hasDropdown }" @click="menuClick">
      <b-icon v-if="item.icon" :icon="item.icon" :class="{ 'has-update-mark': item.updateMark }" custom-size="default" />
      <span v-if="item.label" :class="{ 'menu-item-label': !!item.icon }">{{ item.label }}</span>
      <div v-if="hasDropdown" class="dropdown-icon" @click.stop="expandClicked">
        <b-icon :icon="dropdownIcon" custom-size="default" />
      </div>
    </a>
    <aside-menu-list v-if="hasDropdown" :menu="item.menu" :is-submenu-list="true" @child-active="childActive" />
  </li>
</template>

<script>
export default {
  name: "AsideMenuItem",
  components: {
    AsideMenuList: () => import("@/components/AsideMenuList")
  },
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      isDropdownActive: this.item?.defaultOpen
    };
  },
  computed: {
    hasDropdown() {
      return !!this.item.menu;
    },
    dropdownIcon() {
      return this.isDropdownActive ? "minus" : "plus";
    },
    itemHref() {
      return this.item.href ? this.item.href : null;
    },
    isActive() {
      const active = this.$route.name === this.item.to;
      if (active) {
        this.$emit("child-active");
      }
      return active;
    }
  },
  methods: {
    menuClick() {
      this.$emit("menu-click", this.item);

      // Click on menu, should open submenu
      if (this.hasDropdown && !this.isDropdownActive) {
        this.isDropdownActive = !this.isDropdownActive;
      }

      if (!this.isActive) {
        this.$router.push({ name: this.item.to });
      }
    },
    expandClicked() {
      this.isDropdownActive = !this.isDropdownActive;
    },
    childActive() {
      // expand the sub menu
      this.isDropdownActive = true;
    }
  }
};
</script>
