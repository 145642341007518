export default {
  Enum: {
    UnKnown: -1,
    Day: 0,
    WeekStarting: 1,
    WeekEnding: 2,
    Month: 3,
    Quarter: 4,
    SixMonths: 5,
    Year: 6
  },
  Label: ["Date", "Week Starting", "Week Ending", "Month", "Quarter", "Six Months", "Year"],
  Plural: ["Days", "Weeks", "Weeks", "Months", "Quarters", "Periods", "Years"],
  PlaceHolder: ["Select a date", "Select a Week", "Select a Week", "Select Month", "Select Quarter", "Select period", "Select Year"],
  NumDaysMonths: [1, 7, 7, 1, 3, 6, 12],
  UseMonthsFrom: 3
};
