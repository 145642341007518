import PermissionsEnum from "@/enums/permissions";
import routeParams from "@/common/routeParams";

export default [
  {
    meta: {
      title: "Locations",
      permission: PermissionsEnum.AdminLocations
    },
    name: "locations",
    path: "/admin-locations",
    component: () => import(/* webpackChunkName: "Locations" */ "@/views/admin/Locations.vue")
  },
  {
    meta: {
      title: "Users",
      permission: PermissionsEnum.AdminUsers
    },
    name: "users",
    path: "/users",
    component: () => import(/* webpackChunkName: "User" */ "@/views/admin/Users.vue")
  },
  {
    meta: {
      title: "New User",
      permission: PermissionsEnum.AdminUsers
    },
    path: "/users/new",
    name: "user.new",
    component: () => import(/* webpackChunkName: "User" */ "../views/admin/UserEdit.vue")
  },
  {
    meta: {
      title: "Edit User",
      permission: PermissionsEnum.AdminUsers
    },
    path: "/users/:id",
    name: "user.edit",
    component: () => import(/* webpackChunkName: "User" */ "../views/admin/UserEdit.vue"),
    props: routeParams.idAsInt
  },
  {
    meta: {
      title: "Bed Management",
      permission: PermissionsEnum.Admin
    },
    name: "beds",
    path: "/beds",
    component: () => import(/* webpackChunkName: "Beds" */ "@/views/admin/BedManagement.vue")
  },
  {
    meta: {
      title: "Care Plans",
      permission: PermissionsEnum.AdminCarePlans
    },
    name: "careplans",
    path: "/careplans",
    component: () => import(/* webpackChunkName: "CarePlan" */ "@/views/admin/CarePlans.vue")
  },
  {
    meta: {
      title: "New Care Plans",
      permission: PermissionsEnum.AdminCarePlans,
      dirtyHandledInSubcomponent: true
    },
    name: "careplan.new",
    path: "/careplans/new",
    component: () => import(/* webpackChunkName: "CarePlan" */ "@/views/admin/CarePlanEdit.vue")
  },
  {
    meta: {
      title: "Edit Care Plans",
      permission: PermissionsEnum.AdminCarePlans,
      dirtyHandledInSubcomponent: true
    },
    name: "careplan.edit",
    path: "/careplans/:id",
    component: () => import(/* webpackChunkName: "CarePlan" */ "@/views/admin/CarePlanEdit.vue"),
    props: routeParams.idAsInt
  },

  {
    meta: {
      title: "Organisational Controls",
      permission: PermissionsEnum.AdminOrgControls
    },
    name: "orgcontrols",
    path: "/orgcontrols",
    component: () => import(/* webpackChunkName: "Locations" */ "@/views/admin/OrgControls.vue")
  },
  {
    meta: {
      title: "Configuration",
      permission: PermissionsEnum.AdminOrgConfig
    },
    name: "orgconfig",
    path: "/orgconfig",
    component: () => import(/* webpackChunkName: "Orgconfig" */ "@/views/admin/OrgConfig.vue")
  }
];
