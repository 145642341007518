import router from "../../../router/index.js";

export default {
  namespaced: true,
  state() {
    return {
      lastFetch: null,
      organisations: []
    };
  },
  mutations: {
    setOrgs(state, payload) {
      state.organisations = payload;
    },
    setFetchTimestamp(state) {
      state.lastFetch = new Date().getTime();
    }
  },
  actions: {
    async loadOrgs(context, payload) {
      if (!payload.forceRefresh && !context.getters.shouldUpdate) {
        return;
      }

      const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}organisations`, {
        headers: {
          Authorization: "Bearer " + context.rootGetters.token
        }
      });

      if (response.status === 401) {
        router.replace("/login?redirect=organisations");
      } else {
        const responseData = await response.json();
        if (!response.ok) {
          const error = new Error(responseData.message || "Failed to get orgs!");
          throw error;
        }

        context.commit("setOrgs", responseData);
        context.commit("setFetchTimestamp");
      }
    }
  },
  getters: {
    orgs(state) {
      return state.organisations;
    },
    hasOrgs(state) {
      return state.organisations && state.organisations.length > 0;
    },
    shouldUpdate(state) {
      const lastFetch = state.lastFetch;
      if (!lastFetch) {
        return true;
      }
      const currentTimeStamp = new Date().getTime();
      return (currentTimeStamp - lastFetch) / 1000 > 60;
    }
  }
};
