export default {
  AdminUsers: 1,
  AdminResidents: 2,
  AdminOrganisations: 3,

  AdminLocations: 10,

  MenuDashboard: 20,

  KPIEntry: 30,
  KPIReports: 31,
  KPIEntryAdd: 32,
  KPIUpdateDueDate: 33,
  KPIAdmin: 34,

  Residents: 40,
  ResidentAdd: 41,
  ResidentUpdate: 42,
  AssessmentAdd: 43,
  AssessmentUpdate: 44,
  ObservationAdd: 45,
  ObservationUpdate: 46,

  AdminCarePlans: 50,
  CarePlanUpdate: 51,

  Risks: 60,
  RiskAdd: 61,
  RiskUpdate: 62,

  AdminOrgControls: 70,
  AdminOrgConfig: 71,

  ResidentActionUpdate: 80,

  WoundAdd: 90,
  WoundUpdate: 91,

  RoomAdmin: 100,

  KPITest: 200,

  SystemAdmin: 999
};
