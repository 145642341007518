import axios from "axios";
import store from "./store/index";
import router from "./router/index";

let isRefreshing = false;

// Failed queue for handling multiple request where token is refreshed
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

api.offlineMsg = "Operation canceled: Network offline";

// get token from store
api.interceptors.request.use(
  config => {
    // cancel if offline
    if (navigator.onLine === false) config.cancelToken = new axios.CancelToken(cancel => cancel(api.offlineMsg));

    const token = store.getters.token;
    if (token) {
      config.headers.common.Authorization = "Bearer " + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Used to handle common responses
api.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  async error => {
    if (!error.response) {
      return Promise.reject(error);
    }
    if (error.response.status) {
      const originalRequest = error.config;
      switch (error.response.status) {
        case 400:
          // do something
          break;

        case 401:
          if (error.response.headers["token-expired"] && !originalRequest._retry) {
            // if already refreshing, wait until finished
            if (isRefreshing) {
              return new Promise(function(resolve, reject) {
                failedQueue.push({ resolve, reject });
              })
                .then(token => {
                  originalRequest.headers.Authorization = "Bearer " + token;
                  return axios(originalRequest);
                })
                .catch(err => {
                  return Promise.reject(err);
                });
            }

            isRefreshing = true;
            return await store.dispatch("refreshToken").then(() => {
              return new Promise(function(resolve) {
                // try again with new token
                originalRequest._retry = true;
                const newToken = store.getters.token;
                originalRequest.headers.Authorization = "Bearer " + newToken;
                processQueue(null, newToken);
                resolve(api(originalRequest));
              })
                .catch(err => {
                  processQueue(err, null);
                })
                .finally(() => {
                  isRefreshing = false;
                });
            });
          } else {
            // logout will be called in the show alert so no need call it here
            // clear the failed Queue
            failedQueue = [];
            isRefreshing = false;
          }
          break;
        case 403: // Not Authorised
          // Promise.reject called and message show in showErrorAlert
          break;
        case 404:
          // Not found, Promise.reject called
          break;
        case 502:
          setTimeout(() => {
            router.replace({
              path: "/login",
              query: {
                redirect: router.currentRoute.fullPath
              }
            });
          }, 1000);
      }
      return Promise.reject(error);
    }
  }
);

export default api;
