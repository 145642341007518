<template>
  <footer v-show="isFooterBarVisible" class="footer" :class="{ stickyRatingMargin: isStickyRatingVisible }">
    <div class="container-fluid">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div class="footer-copyright">
              <b>&copy; {{ year }} Xyea</b> &mdash; Life Plan
              <span class="tag">{{ version }}</span>
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="logo">
              <a href="https://xyea.ie">
                <img src="../assets/xyea-logo.png" alt="Xyea.ie" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";

export default {
  name: "FooterBar",
  data() {
    return {
      version: `v${process.env.VUE_APP_VERSION} - ${process.env.VUE_APP_ENV_NAME}`
    };
  },
  computed: {
    year() {
      return dayjs().year();
    },
    ...mapState(["isFooterBarVisible", "isStickyRatingVisible"])
  }
};
</script>
