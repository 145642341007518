import api from "@/api.js";

export default {
  namespaced: true,
  state() {
    return {
      pharmacies: []
    };
  },
  mutations: {
    setPharmacies(state, payload) {
      state.pharmacies = payload;
    },
    addPharmacy(state, newPharmacy) {
      state.pharmacies.push(newPharmacy);
    }
  },
  actions: {
    async loadPharmacies(context, payload) {
      await api
        .get("pharmacy/")
        .then(r => {
          context.commit("setPharmacies", r.data);
        })
        .catch(e => {
          // alerts.showErrorAlert(e, "Error loading Resident details");
        });
    },
    addPharmacy(context, newPharmacy) {
      context.commit("addPharmacy", newPharmacy);
    }
  },
  getters: {
    pharmacies(state) {
      return state.pharmacies;
    }
  }
};
