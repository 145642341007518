import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";
import { ToastProgrammatic as Toast } from "buefy";

import assessmentRoutes from "./assessments.js";
import adminRoutes from "./admin.js";
import kpiRoutes from "./kpis.js";

import routeParams from "@/common/routeParams";
import PermissionsEnum from "@/enums/permissions";

Vue.use(VueRouter);

const mainRoutes = [
  {
    path: "/",
    name: "default",
    component: () => import("../views/Default.vue")
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: "Dashboard",
      permission: PermissionsEnum.MenuDashboard
    },
    path: "/dashboard",
    name: "dashboard",
    component: () => import(/* webpackChunkName: "residents" */ "../views/Home.vue")
  },
  {
    meta: {
      title: "Organisations",
      permission: PermissionsEnum.AdminOrganisations
    },
    path: "/organisations",
    name: "orgs",
    component: () => import(/* webpackChunkName: "residents" */ "../views/Organisations.vue")
  },
  {
    meta: {
      title: "Residents",
      permission: PermissionsEnum.Residents,
      showResidentOnPage: true
    },
    name: "residents",
    path: "/residents",
    component: () => import(/* webpackChunkName: "residents" */ "../views/Residents.vue")
  },
  {
    meta: {
      title: "New Resident",
      permission: PermissionsEnum.ResidentAdd,
      showResidentOnPage: true
    },
    path: "/residents/new",
    name: "resident.new",
    component: () => import(/* webpackChunkName: "resident-form" */ "../views/ResidentForm.vue")
  },
  {
    meta: {
      title: "New Enquiry",
      permission: PermissionsEnum.ResidentAdd
    },
    path: "/enquiry/new",
    name: "enquiry.new",
    component: () => import(/* webpackChunkName: "enquiry-form" */ "../views/ResidentEnquiry.vue")
  },
  {
    meta: {
      title: "Edit Resident",
      permission: PermissionsEnum.Residents,
      showResidentOnPage: true
    },
    path: "/residents/:id",
    name: "resident.edit",
    component: () => import(/* webpackChunkName: "resident-form" */ "../views/ResidentForm.vue"),
    props: routeParams.idAsInt
  },

  {
    meta: {
      title: "Risks",
      permission: PermissionsEnum.Risks
    },
    name: "risks",
    path: "/risks",
    component: () => import(/* webpackChunkName: "risks" */ "../views/Risks.vue")
  },
  {
    meta: {
      title: "New Risk",
      permission: PermissionsEnum.RiskAdd
    },
    path: "/risk/new",
    name: "risk.new",
    component: () => import(/* webpackChunkName: "risks-edit" */ "../views/RiskEdit.vue")
  },
  {
    meta: {
      title: "Edit Risk",
      permission: PermissionsEnum.Risks
    },
    path: "/risk/:id",
    name: "risk.edit",
    component: () => import(/* webpackChunkName: "risks-edit" */ "../views/RiskEdit.vue"),
    props: routeParams.idAsInt
  },
  {
    meta: {
      title: "Risk Actions",
      permission: PermissionsEnum.Risks
    },
    path: "/riskactions",
    name: "riskactions",
    component: () => import(/* webpackChunkName: "risk-actions" */ "../views/RiskActions.vue")
  },
  {
    meta: {
      title: "Profile"
    },
    path: "/profile",
    name: "profile",
    component: () => import(/* webpackChunkName: "profile" */ "../views/Profile.vue")
  },

  {
    path: "/full-page",
    component: () => import(/* webpackChunkName: "full-page" */ "../views/FullPage.vue"),
    children: [
      {
        meta: {
          title: "Login",
          requiresUnauth: true
        },
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "full-page" */ "../views/full-page/Login.vue")
      },
      {
        meta: {
          title: "Password Recovery",
          requiresUnauth: true
        },
        path: "/password-recovery",
        name: "password-recovery",
        component: () => import(/* webpackChunkName: "full-page" */ "../views/full-page/PasswordRecovery.vue")
      },
      {
        meta: {
          title: "Password Reset",
          requiresUnauth: true
        },
        path: "/password-reset",
        name: "password-reset",
        component: () => import(/* webpackChunkName: "full-page" */ "../views/full-page/PasswordReset.vue")
      },
      {
        meta: {
          title: "Error v.1"
        },
        path: "/error-in-card",
        name: "error-in-card",
        component: () => import(/* webpackChunkName: "full-page" */ "../views/full-page/Error.vue")
      },
      {
        meta: {
          title: "Error v.2"
        },
        path: "/error-simple",
        name: "error-simple",
        component: () => import(/* webpackChunkName: "full-page" */ "../views/full-page/Error.vue"),
        props: { isInCard: false }
      },
      {
        meta: {
          title: "Lock screen",
          requiresUnauth: false
        },
        path: "/lock-screen",
        name: "lock-screen",
        component: () => import(/* webpackChunkName: "full-page" */ "../views/full-page/LockScreen.vue")
      }
    ]
  }
];

const routes = [...mainRoutes, ...assessmentRoutes, ...adminRoutes, ...kpiRoutes];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach(function(to, from, next) {
  // set previous back, for use with cancel/back
  store.commit("setPreviousPage", from.name);

  // Assume all pages requires Auth unless requiresUnauth specified
  if (!to.meta.requiresUnauth && !store.getters.isAuthenticated) {
    next("/login?redirect=" + to.path.substring(1)); // no need for inital /
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next(store.getters.defaultRoute);
    // if specifc permission required check that
  } else if (to.meta.permission && !store.getters.hasPermission(to.meta.permission)) {
    next(store.getters.defaultRoute);
    Toast.open({ message: "You do not have permission to access that page", type: "is-danger" });
  } else {
    next();
  }
});

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach(to => {
  store.commit("asideMobileStateToggle", false);

  /* Default title tag */
  const defaultDocumentTitle = process.env.VUE_APP_TITLE;

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`;
  } else {
    document.title = defaultDocumentTitle;
  }

  // set ShowResident based on router info
  store.commit("resident/showResidentOnPage", to.meta.showResidentOnPage);
  // set HideFooter based on router info
  store.commit("hideFooter", to.meta.hideFooter ?? false);
});

export default router;
