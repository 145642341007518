import { GetSelectLookup } from "./common";

const SevereEnum = {
  Severe: 0,
  Moderate: 1,
  Mild: 2,
  Normal: 3
};

const SevereLookup = ["Severe", "Moderate", "Mild", "Normal"];

const colour = ["has-text-danger", "has-text-high-warning", "has-text-warning", "has-text-success"];

export default {
  Enum: SevereEnum,
  SelectLookup: GetSelectLookup(SevereEnum, SevereLookup),
  Lookup: SevereLookup,
  Colour: colour,
  Icon: ["alert-circle", "alert", "alert-outline", "check"]
};
