const RiskEnum = {
  LowRisk: 0,
  MediumRisk: 1,
  HighRisk: 2,
  VeryHighRisk: 3
};

const LikelhoodEnum = {
  Rare: 1,
  Unlikely: 2,
  Possible: 3,
  Likely: 4,
  AlmostCertain: 5
};

const ImpactEnum = {
  Negligible: 1,
  Minor: 2,
  Moderate: 3,
  Major: 4,
  Extreme: 5
};

export default {
  Enum: RiskEnum,
  Lookup: ["Low Risk", "Medium Risk", "High Risk", "Very High Risk"],
  SelectLookup: [
    { id: RiskEnum.LowRisk, value: "Low Risk" },
    { id: RiskEnum.MediumRisk, value: "Medium Risk" },
    { id: RiskEnum.HighRisk, value: "High Risk" },
    { id: RiskEnum.VeryHighRisk, value: "Very High Risk" }
  ],
  SelectLookup3: [
    { id: RiskEnum.LowRisk, value: "Low Risk" },
    { id: RiskEnum.MediumRisk, value: "Medium Risk" },
    { id: RiskEnum.HighRisk, value: "High Risk" }
  ],
  SelectLikelihood: [
    { id: LikelhoodEnum.Rare, value: "Rare" },
    { id: LikelhoodEnum.Unlikely, value: "Unlikely" },
    { id: LikelhoodEnum.Possible, value: "Possible" },
    { id: LikelhoodEnum.Likely, value: "Likely" },
    { id: LikelhoodEnum.AlmostCertain, value: "Almost Certain" }
  ],
  SelectImpact: [
    { id: ImpactEnum.Negligible, value: "Negligible" },
    { id: ImpactEnum.Minor, value: "Minor" },
    { id: ImpactEnum.Moderate, value: "Moderate" },
    { id: ImpactEnum.Major, value: "Major" },
    { id: ImpactEnum.Extreme, value: "Extreme" }
  ],
  LookupShort: ["Low", "Medium", "High", "Very High"],
  Icon4: ["check", "alert-outline", "alert", "alert-circle"],
  Icon3: ["check", "alert-outline", "alert-circle"],
  Colour4: ["has-text-success", "has-text-warning", "has-text-high-warning", "has-text-danger"],
  Colour3: ["has-text-success", "has-text-high-warning", "has-text-danger"]
};
