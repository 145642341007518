import api from "@/api.js";
import alerts from "@/common/alerts";
import ReportingPeriods from "@/enums/reportingPeriod";

export default {
  namespaced: true,
  state() {
    return {
      auditTypes: null,
      selectedAuditTypeId: null
    };
  },
  mutations: {
    setAuditTypes(state, auditTypes) {
      state.auditTypes = auditTypes;
    },
    setSelectedAuditType(state, auditType) {
      state.selectedAuditTypeId = auditType;
    }
  },
  actions: {
    async getAuditTypes(context) {
      await api
        .get("audittypes")
        .then(r => context.commit("setAuditTypes", r.data))
        .catch(e => alerts.showErrorAlert(e, "Failed to load Audit Types"));
    },
    setAuditType(context, auditType) {
      context.commit("setSelectedAuditType", auditType);
    }
  },
  getters: {
    auditTypes(state) {
      return state.auditTypes;
    },
    selectedAuditTypeId(state) {
      return state.selectedAuditTypeId;
    },
    selectedAuditType(state) {
      return state.selectedAuditTypeId && state.auditTypes.length
        ? state.auditTypes.find(a => a.auditTypeId === state.selectedAuditTypeId)
        : null;
    },
    selectedAuditTypeName(state, getters) {
      return getters.selectedAuditType?.name ?? "";
    },
    selectedAuditTypeReportingPeriod(state, getters) {
      return getters.selectedAuditType?.reportingPeriod ?? ReportingPeriods.Enum.UnKnown;
    },
    selectedAuditTypeReportingWeekDay(state, getters) {
      return getters.selectedAuditType?.reportingWeekDay ?? 0;
    },
    firstAuditTypeId(state) {
      return state.auditTypes?.[0]?.auditTypeId;
    }
  }
};
