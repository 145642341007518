import api from "@/api.js";
import arrays from "@/common/arrays";

// var lookupInfo = {
//   lookupType: Which lookup we are getting
//   parentId: ParentId if dependent lookups from other control
//   gender: return only specfic gender values
//   withCategories: true -> returns lookup categories with arry of each lookup (use withCategories prop in SelectLookup )
// }

export default {
  namespaced: true,
  state() {
    return {
      lookups: [],
      noData: [],
      fetching: [], // array on lookup type ids that is currently being fetched, this is prevent multiple calls to same lookup
      fetchingPromise: {} // object of current promised calling serving
    };
  },
  mutations: {
    addLookUps(state, lookups) {
      state.lookups = state.lookups.concat(lookups);
    },
    noLookUps(state, lookupInfo) {
      if (lookupInfo.parentId) {
        const parent = state.lookups.find(l => {
          return l.lookupId === lookupInfo.parentId;
        });
        parent.noChildren = true;
      } else {
        // record no data for this lookup, so we can return empty array rather than null
        state.noData.push(lookupInfo.lookupType);
      }
    },
    addnewLookup(state, newLookup) {
      state.lookups.push(newLookup);
    }
  },
  actions: {
    getLookups({ commit, state }, lookupInfo) {
      if (state.fetching.includes(lookupInfo.lookupType)) {
        // return promise of previous call
        return state.fetchingPromise["lookup" + lookupInfo.lookupType];
      }
      state.fetching.push(lookupInfo.lookupType);

      var url = "lookup/" + lookupInfo.lookupType;
      if (lookupInfo.parentId) url += "?parentId=" + lookupInfo.parentId;
      const promise = api
        .get(url)
        .then(r => {
          if (r.data.length) commit("addLookUps", r.data);
          else commit("noLookUps", lookupInfo); // mark as having none
        })
        .catch(e => {
          // alerts.showErrorAlert(e, "Error loading Resident details");
        })
        .finally(() => arrays.removeItem(state.fetching, lookupInfo.lookupType));

      state.fetchingPromise["lookup" + lookupInfo.lookupType] = promise;

      return promise;
    },
    async addLookup(context, newLookup) {
      return await api.post("lookup/", newLookup).then(r => context.commit("addnewLookup", r.data));
    }
  },
  getters: {
    getValues(state) {
      return lookupType =>
        state.lookups
          .filter(l => {
            return l.lookupType === lookupType;
          })
          .map(t => t.name);
    },
    getLookups(state) {
      return lookupInfo => {
        var data = state.lookups.filter(l => {
          return (
            l.lookupType === lookupInfo.lookupType &&
            (!lookupInfo.parentId || l.parentId === lookupInfo.parentId) &&
            (!lookupInfo.gender || !l.genderSpecific || l.genderSpecific === lookupInfo.gender)
          );
        });

        // return null if no data loaded yet, but empty array if we've loaded data but there isn't any
        if (data.length === 0) {
          return state.noData.includes(lookupInfo.lookupType) ? [] : null;
        }

        if (!lookupInfo.withCategories) return data;

        // group categories
        var categories = [...new Set(data.map(v => v.category))];
        var categoryData = [];
        categories.forEach(c => {
          categoryData.push({
            category: c,
            lookups: data.filter(l => l.category === c)
          });
        });
        return categoryData;
      };
    },
    getLookup(state) {
      return lookupId =>
        state.lookups.find(l => {
          return l.lookupId === lookupId;
        });
    }
  }
};
