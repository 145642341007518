export default {
  namespaced: true,
  state() {
    return {};
  },
  mutations: {
    setSearch(state, search) {
      state[search.url] = search.search;
    }
  },
  getters: {
    savedSearch(state) {
      return url => {
        return state[url] ?? {};
      };
    }
  }
};
