import dayjs from "dayjs";

export default {
  namespaced: true,
  state() {
    return {
      resident: null,
      showResidentOnPage: false
    };
  },
  mutations: {
    setResident(state, payload) {
      state.resident = { ...payload }; // set as a copy so immediate updates are no reflected on top nav
    },
    showResidentOnPage(state, payload) {
      state.showResidentOnPage = payload;
    }
  },
  actions: {
    setResident(context, payload) {
      localStorage.setItem("resident", JSON.stringify(payload));
      context.commit("setResident", payload);
    },
    getStoredResident(context) {
      const resident = localStorage.getItem("resident");
      const residentData = resident ? JSON.parse(resident) : {};
      if (residentData) {
        context.commit("setResident", residentData);
      }
    }
  },
  getters: {
    getStoredResident() {
      const resident = localStorage.getItem("resident");
      return resident ? JSON.parse(resident) : null;
    },
    resident(state, getters) {
      return state.resident ?? getters.getStoredResident;
    },
    residentId(_, getters) {
      return getters.resident?.residentId ?? 0;
    },
    residentName(_, getters) {
      return getters.resident?.fullName;
    },
    residentFirstName(_, getters) {
      return getters.resident?.firstName;
    },
    residentKnownAs(_, getters) {
      return getters.resident?.knownAs;
    },
    residentDob(_, getters) {
      return getters.resident?.dob;
    },
    residentMedicalGender(_, getters) {
      return getters.resident?.medicalGender;
    },
    residentAge(_, getters) {
      return getters.resident ? dayjs().diff(getters.resident.dob, "years") : 0;
    },
    residentImage(_, getters) {
      return getters.resident?.avatarUrl;
    },
    showResident(state, getters) {
      return state.showResidentOnPage && getters.residentId;
    }
  }
};
