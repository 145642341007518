<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top noprint">
    <div class="navbar-brand" :class="{ 'is-flex-grow-1': largeResident }">
      <a class="navbar-item" @click.prevent="menuToggleMobile">
        <b-icon :icon="menuToggleMobileIcon" />
      </a>
      <div class="navbar-item has-control no-left-space-touch" :class="{ 'is-flex-grow-1': largeResident }">
        <!-- Need Level class on smallResident Resident for mobile to make it fit one line -->
        <div
          v-if="showResident"
          class="resident is-mobile"
          :class="{ smallResident: !largeResident, level: !largeResident, largeResident: largeResident }"
          @click="toggleLarge"
        >
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <div class="is-user-avatar">
                <img :src="residentImage" />
              </div>
            </div>
            <template v-if="!largeResident">
              <div class="level-item">{{ residentName }}</div>
              <div v-if="residentKnownAs" class="level-item"><strong>Known As:</strong></div>
              <div v-if="residentKnownAs" class="level-item">{{ residentKnownAs }}</div>
              <div class="level-item"><strong>Age:</strong></div>
              <div class="level-item">{{ residentAge }}</div>
            </template>

            <div v-else class="columns is-flex-grow-1 pt-2">
              <a class="closeButton" href="#" @click.stop="hideLarge">
                <b-icon icon="close-circle-outline" size="is-small" />
              </a>

              <div class="column">
                <div class="columns">
                  <div class="column">
                    <p class="is-size-2 has-text-weight-bold has-text-centered">{{ residentName }}</p>
                  </div>
                </div>
                <label-field label="Age" :field="residentAge" />
                <label-field label="Age" :field="residentAge" />
                <label-field label="Age" :field="residentAge" />
              </div>
              <div class="column">
                <label-field label="Known As" :field="knownOrFirst" size="2" />
                <label-field label="Age" :field="residentAge" />
                <label-field label="Age" :field="residentAge" />
                <label-field label="Age" :field="residentAge" />
              </div>
              <div class="column">
                <label-field label="Age" :field="residentAge" size="2" />
                <label-field label="Age" :field="residentAge" />
                <label-field label="Age" :field="residentAge" />
                <button v-if="showFullProfileBtn" @click.stop="showProfile" class="button is-primary is-pulled-right">
                  <b-icon icon="account-edit" size="is-small" />Full Profile
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="nonProduction" class="navbar-brand is-right nonProduction">
      <p>{{ nonProduction }}</p>
    </div>
    <div class="navbar-brand is-right">
      <a class="navbar-item navbar-item-menu-toggle is-hidden-desktop" @click.prevent="menuNavBarToggle">
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default" />
      </a>
    </div>
    <div v-if="!largeResident" class="navbar-menu fadeIn animated faster" :class="{ 'is-active': isMenuNavBarActive }">
      <div class="navbar-end">
        <nav-bar-menu class="has-divider has-user-avatar">
          <user-avatar :avatar="avatarUrl" />
          <div class="is-user-name">
            <span>{{ userName }}</span>
          </div>

          <template v-slot:dropdown>
            <div class="navbar-dropdown">
              <router-link to="/profile" class="navbar-item" exact-active-class="is-active">
                <b-icon icon="account" custom-size="default" />
                <span>My Profile</span>
              </router-link>
              <a class="navbar-item">
                <b-icon icon="settings" custom-size="default"></b-icon>
                <span>Settings</span>
              </a>
              <a class="navbar-item">
                <b-icon icon="email" custom-size="default"></b-icon>
                <span>Messages</span>
              </a>
              <hr class="navbar-divider" />
              <a class="navbar-item">
                <b-icon icon="logout" custom-size="default"></b-icon>
                <span>Log Out</span>
              </a>
            </div>
          </template>
        </nav-bar-menu>
        <a class="navbar-item" v-if="permission === 'denied'" @click="notificationsDenied">
          <b-icon icon="bell-off-outline" custom-size="default" />
        </a>
        <a class="navbar-item" v-if="permission === 'default'" @click="checkAllowNotifications">
          <b-icon icon="bell-outline" custom-size="default" />
        </a>
        <nav-bar-menu v-if="permission === 'granted'" class="has-divider navPosRight" @opened="refreshDBNotifications">
          <notification-bell
            :size="25"
            :count="notifications.length"
            :upperLimit="50"
            counterLocation="upperRight"
            counterStyle="roundRectangle"
            counterBackgroundColor="#FF0000"
            counterTextColor="#FFFFFF"
            iconColor="#000000"
            class="notificationBell"
            :animated="true"
            title="Notifications"
          />

          <template v-slot:dropdown>
            <div class="navbar-dropdown">
              <div class="navbar-item" v-if="notifications.length === 0">
                <b-icon icon="bell-off-outline" custom-size="default"></b-icon>
                <span>No new notifications</span>
              </div>

              <div class="navbar-item" v-else v-for="notification in notifications" :key="notification.dbNotificationId">
                <a class="navbar-item">
                  <b-icon icon="bell" custom-size=""></b-icon>
                  <div>
                    <p class="notificationTitle">{{ notification.title }}</p>
                    <p class="notificationBody">{{ notification.body }}</p>
                  </div>
                </a>
              </div>
            </div>
          </template>
        </nav-bar-menu>
        <a class="navbar-item is-desktop-icon-only" title="Log out" @click="logout">
          <b-icon icon="logout" custom-size="default" />
          <span>Log out</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import NavBarMenu from "@/components/NavBarMenu";
import UserAvatar from "@/components/UserAvatar";
import LabelField from "@//components/NavLabelField.vue";
import createBodyClickListener from "@/components/contextMenu/body-click-listener.js";
import NotificationBell from "vue-notification-bell";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp } from "firebase/app";

// Add the Firebase products that you want to use
import { getAnalytics, logEvent } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

export default {
  name: "NavBar",
  components: {
    UserAvatar,
    NavBarMenu,
    LabelField,
    NotificationBell
  },
  data() {
    return {
      isMenuNavBarActive: false,
      largeResident: false,
      pushToken: null,
      permission: null,
      notifications: [],
      bodyClickListener: createBodyClickListener(e => {
        const outsideClick = this.largeResident && !this.$el.contains(e.target);
        if (outsideClick) this.hideLarge();
      })
    };
  },
  computed: {
    menuNavBarToggleIcon() {
      return this.isMenuNavBarActive ? "close" : "dots-vertical";
    },
    menuToggleMobileIcon() {
      return this.isAsideMobileExpanded ? "backburger" : "forwardburger";
    },
    ...mapState(["isNavBarVisible", "isAsideMobileExpanded"]),
    ...mapGetters(["userName", "avatarUrl"]),
    ...mapGetters("resident", [
      "showResident",
      "residentId",
      "residentName",
      "residentKnownAs",
      "residentFirstName",
      "residentImage",
      "residentAge"
    ]),
    ...mapGetters(["isAuthenticated", "orgId"]),
    nonProduction() {
      return !this.largeResident && process.env.VUE_APP_ENV_NAME !== "Production" ? process.env.VUE_APP_ENV_NAME : null;
    },
    showFullProfileBtn() {
      return this.$route.name !== "resident.edit";
    },
    knownOrFirst() {
      return this.residentKnownAs || this.residentFirstName;
    }
  },
  watch: {
    largeResident(newVal, oldVal) {
      if (oldVal === true && newVal === false) this.bodyClickListener.stop();
      if (oldVal === false && newVal === true) this.bodyClickListener.start();
    },
    permission(newVal) {
      if (newVal === "granted" && process.env.NODE_ENV === "production") {
        this.initPushMessages();
      }
    },
    isAuthenticated: {
      immediate: true,
      handler(auth) {
        if (auth) {
          // App Initialisation
          this.checkAllowNotifications();
          this.refreshDBNotifications();
          this.$store.dispatch("orgconfig/loadConfig", this.orgId);
        }
      }
    }
  },
  mounted() {
    this.$router.afterEach(() => {
      this.isMenuNavBarActive = false;
    });
  },

  // define the methods for SignalR
  sockets: {
    ReceiveMessage(data) {
      console.log("sockets ReceiveMessage", data);
      this.showNotification(data.title, data.message, () => {
        if (this.$route.name !== "actions" || data.residentId !== this.$store.getters["resident/residentId"]) {
          this.$router.push({ name: "actions", params: { selResidentId: data.residentId } });
        }
      });

      this.notifications.unshift(data);
    }
  },
  methods: {
    hubConnect() {
      this.$socket.invoke("hubConnect", 123).then(() => {});
    },
    menuToggleMobile() {
      this.$store.commit("asideMobileStateToggle");
    },
    menuNavBarToggle() {
      this.isMenuNavBarActive = !this.isMenuNavBarActive;
    },
    logout() {
      this.$store.dispatch("logout");
    },
    showLarge() {
      this.largeResident = true;
    },
    hideLarge() {
      this.largeResident = false;
    },
    toggleLarge() {
      this.largeResident = !this.largeResident;
    },
    showProfile() {
      this.hideLarge();
      this.$router.push({ name: "resident.edit", params: { id: this.residentId } });
    },
    async registerForPush(pushManager) {
      try {
        const subscription = await pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: new Uint8Array([
            /* … */
          ])
        });
        await fetch("https://example.com/push/subscribe", { method: "POST", body: JSON.stringify(subscription.toJSON()) });
      } catch (error) {
        console.error(error);
      }
    },
    async checkAllowNotifications() {
      this.permission = Notification.permission;

      if (this.permission === "default" && localStorage.getItem("notificationPermission") !== "no") {
        this.$buefy.dialog.confirm({
          title: "Allow Notifications?",
          message: "Allow Xyea to send you notifications to keep you informed or important updates and overdue actions?",
          cancelText: "No Thanks",
          confirmText: "Absolutely",
          type: "is-primary",
          hasIcon: true,
          icon: "bell",
          onConfirm: async () => {
            this.permission = await Notification.requestPermission();
            if (this.permission === "granted") {
              const noti = new Notification("Xyea Life Plan Notifications Enabled", {
                body: "Thanks for enabling notifictions. You will see only relevant notifications to help ensure you don't miss anthing important.",
                icon: "img/icons/windows/windows-squarelogo-270-270.png"
              });
              noti.onclick = null; // () => alert("clicked");
            }
          },
          onCancel: () => {
            localStorage.setItem("notificationPermission", "no");
          }
        });
      }
    },
    notificationsDenied() {
      this.permission = Notification.permission;
      if (this.permission === "denied") {
        this.$buefy.toast.open({
          message: "You have previously denied notifications for this site, you will need to allow them again.",
          type: "is-warning",
          position: "is-top"
        });
        // window.location.href = "chrome://settings/content/siteDetails?site=http%3A%2F%2Flocalhost%3A8080%2F";
      }
    },
    initPushMessages() {
      // if ("serviceWorker" in navigator) {
      //   navigator.serviceWorker.register("sw.js");
      //   navigator.serviceWorker.ready.then(registration => {
      //     if ("PushManager" in window) {
      //       document
      //         .querySelector("button.subscribe-for-push")
      //         .addEventListener("click", () => this.registerForPush(registration.pushManager));
      //     }
      //   });
      // }

      // FCM
      var firebaseConfig = {
        apiKey: "AIzaSyCPi78GXFuuPBYgJsD4o7WmJiy2MFC70l4",
        authDomain: "lifeplan-test-b836a.firebaseapp.com",
        projectId: "lifeplan-test-b836a",
        storageBucket: "lifeplan-test-b836a.appspot.com",
        messagingSenderId: "412906906134",
        appId: "1:412906906134:web:08bdb80ae0e4da891b0b37",
        measurementId: "G-ZJKLNLBT3E"
      };
      // Initialize Firebase
      const firebaseApp = initializeApp(firebaseConfig);
      const analytics = getAnalytics();
      logEvent(analytics, "navBar initializeApp");

      console.log(firebaseApp);
      const messaging = getMessaging();

      navigator.serviceWorker.getRegistration().then(swRegistration => {
        console.log("Service worker from  navigator: ", swRegistration);

        getToken(messaging, {
          serviceWorkerRegistration: swRegistration,
          vapidKey: "BJejdNWzP-iqTL8stfwVv5hD4l-kNB-gT7r9aNVYdq-Kn1bvfmBWIM78-2nFUqsIEpjUlvkyYxxoYnhl8-FbCfw"
        })
          .then(currentToken => {
            this.pushToken = currentToken;
            if (currentToken) {
              // Send the token to your server and update the UI if necessary
              // ...
              console.log("currentToken:", currentToken);
            } else {
              // Show permission request UI
              console.log("No registration token available. Request permission to generate one.");
              // ...
            }
          })
          .catch(err => {
            console.log("An error occurred while retrieving token. ", err);
            // ...
          });
      });

      onMessage(messaging, payload => {
        console.log("FCM Message received. ", payload);

        this.showNotification(payload?.notification?.title, payload?.notification?.body);
      });

      // messaging
      //   .requestPermission()
      //   .then(() => {
      //     console.log("Notifications allowed");
      //     return messaging.getToken();
      //   })
      //   .then(token => {
      //     console.log("Token Is : " + token);
      //   })
      //   .catch(err => {
      //     console.log("No permission to send push", err);
      //   });

      // messaging.onMessage(function(payload) {
      //   console.log("Receiving foreground message");
      //   console.log(payload);
      //   var notificationTitle = "vue-cometchat-firebase";
      //   var notificationOptions = {
      //     body: payload.data.alert,
      //     icon: ""
      //   };

      //   var notification = new Notification(notificationTitle, notificationOptions);
      //   notification.onclick = function(event) {
      //     notification.close();
      //     console.log(event);
      //   };
      // });
    },
    showNotification(title, body, onClick) {
      this.$vs.notification({
        title: title,
        text: body,
        // color: "warn",
        duration: 8000,
        icon: "<i class='mdi mdi-bell'/>",
        clickClose: true,
        onClick: onClick
      });
    },
    refreshDBNotifications() {
      this.isLoadingNotification = true;
      this.$http
        .get("notifications")
        .then(r => (this.notifications = r.data))
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving Notifications"))
        .finally(() => (this.isLoadingNotification = false));
    }
  },
  beforeDestroy() {
    this.bodyClickListener.stop();
  }
};
</script>

<style scoped>
.nonProduction {
  color: red;
  font-size: 30px;
  font-weight: bold;
  padding: 3px;
}

.resident,
.is-user-avatar img {
  transition: all 0.6s ease-out;
}
.largeResident {
  flex-grow: 1;
  /* font-size: 2em; */
}
.largeResident .is-user-avatar img {
  max-height: 20em;
}
.largeResident .level-left {
  align-items: start;
}

.smallResident {
  flex-grow: 0;
  /* font-size: 1em; */
}
.smallResident .is-user-avatar img {
  max-height: 1.75em;
}
.smallResident .level-left {
  align-items: center;
}

.closeButton {
  position: absolute;
  top: 5px;
  right: 0;
}
.navPosRight .navbar-dropdown {
  right: -50px;
  left: inherit;
}
</style>

<style>
.notificationBell .notification {
  background-color: inherit;
  padding: 0;
}
.notificationTitle,
.vs-notification__content__header h4 {
  font-weight: bold;
  font-size: 1rem;
}
.notificationBody {
  font-size: 0.75rem;
}
</style>
