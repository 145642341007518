<template>
  <aside v-show="isAsideVisible" class="aside is-placed-left is-expanded">
    <aside-tools :is-main-menu="true">
      <template v-slot:label>
        <div class="mt-2"><img class="toplogo" src="/img/xyea-life-plan.svg" /></div>
      </template>
    </aside-tools>
    <div class="menu is-menu-main">
      <template v-for="(menuGroup, index) in menu">
        <div v-if="menuGroup.sectionName" :key="index">
          <p class="menu-label">
            {{ menuGroup.sectionName }}
          </p>
          <aside-menu-list :key="index" :menu="menuGroup.menu" @menu-click="menuClick" class="submenu" />
        </div>
        <aside-menu-list v-else :key="-index" :menu="menuGroup" @menu-click="menuClick" />
      </template>
    </div>
  </aside>
</template>

<script>
import { mapState } from "vuex";
import AsideTools from "@/components/AsideTools";
import AsideMenuList from "@/components/AsideMenuList";

export default {
  name: "AsideMenu",
  components: { AsideTools, AsideMenuList },
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState(["isAsideVisible"])
  },
  methods: {
    menuClick(item) {
      //
    }
  }
};
</script>

<style>
.toplogo {
  width: 190px; /* Set width was need for Firefox */
}
</style>
