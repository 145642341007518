function GetSelectLookup(enumList, lookup) {
  if (lookup === undefined) {
    lookup = enumList;
    enumList = undefined;
  }
  var select = [];
  if (enumList) {
    for (const [, value] of Object.entries(enumList)) {
      if (lookup[value]) select.push({ id: value, value: lookup[value] });
    }
  } else {
    lookup.forEach((v, i) => {
      if (v) select.push({ id: i, value: v });
    });
  }
  return select;
}

export { GetSelectLookup };
