/* Styles */
import "@/scss/main.scss";

/* Core */
import Vue from "vue";
import Buefy from "buefy";

/* Router & Store */
import router from "./router";
import store from "./store";

/* Service Worker */
import "./registerServiceWorker";

/* Vue. Main component */
import App from "./App.vue";
import api from "./api.js";

import BaseSpinner from "./components/BaseSpinner.vue";
import alerts from "@/common/alerts";

// internal icons
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faCheckCircle,
  faInfoCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faArrowUp,
  faAngleRight,
  faAngleLeft,
  faAngleDown,
  faEye,
  faEyeSlash,
  faCaretDown,
  faCaretUp,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import VueCurrencyInput from "vue-currency-input";

// import VueSignalR from "@kerrpeter/vue-signalr";
import VueSignalR from "@/common/vue_signalr.js";

// Sentry Imports
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import LogRocket from "logrocket";

// https://www.npmjs.com/package/vuejs-clipper
import VueRx from "vue-rx"; // used by vuejs-clipper
import VuejsClipper from "vuejs-clipper/dist/vuejs-clipper.umd";
import "vuejs-clipper/dist/vuejs-clipper.css";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import "./vee-validate";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.use(VueCurrencyInput);
Vue.use(VueRx);
Vue.use(VuejsClipper);

Vue.use(VueSignalR, `${process.env.VUE_APP_BASE_URL}hubs/notifications`);

LogRocket.init(process.env.VUE_APP_LOGROCKET_PROJECT);

// Sentry Confgiure
Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  logErrors: true,
  tracingOptions: {
    trackComponents: true
  }
});

Vue.config.productionTip = false;

library.add(
  faCheck,
  faCheckCircle,
  faInfoCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faArrowUp,
  faAngleRight,
  faAngleLeft,
  faAngleDown,
  faEye,
  faEyeSlash,
  faCaretDown,
  faCaretUp,
  faUpload
);
Vue.component("vue-fontawesome", FontAwesomeIcon);
Vue.use(Buefy, {
  // defaultIconComponent: "vue-fontawesome",
  // defaultIconPack: "fas"
});

Vue.component("base-spinner", BaseSpinner);

/* filters */
// For Vue 3, register the filters globally
// import filters from "./filters/dateFilters";
// Vue.config.globalProperties.$filters = filters;
// But for Vue 2, each is registered seperately
Vue.filter("dateFormat", function (value) {
  return new Intl.DateTimeFormat().format(new Date(value));
});

store.dispatch("tryLogin");

// Make this.$http available in all Vue components
// if needed in js file, then need to import from api.js again
Vue.prototype.$http = api;
Vue.prototype.$alerts = alerts;

// Register a global custom directive called `v-focus`
Vue.directive("focus", {
  // When the bound element is inserted into the DOM...
  inserted: function (el, binding) {
    // Focus the element
    if (binding.value === undefined || binding.value) {
      var ctrl = el.querySelector("input") || el.querySelector("textarea") || el.querySelector("select") || el;
      Vue.nextTick(() => ctrl.focus());
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
