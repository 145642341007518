import { GetSelectLookup } from "./common";

const GenderEnum = {
  Male: 1,
  Female: 2
};

const GenderLookUp = ["", "Male", "Female"];

export default {
  Enum: GenderEnum,
  SelectLookup: GetSelectLookup(GenderEnum, GenderLookUp),

  Values: {
    Male: "Male",
    Female: "Female"
  },
  Lookup: GenderLookUp
};
