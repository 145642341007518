<template>
  <ul :class="{ 'menu-list': !isSubmenuList }">
    <aside-menu-item v-for="(item, index) in menu" :key="index" :item="item" @menu-click="menuClick" @child-active="childActive" />
  </ul>
</template>

<script>
import AsideMenuItem from "@/components/AsideMenuItem";

export default {
  name: "AsideMenuList",
  components: {
    AsideMenuItem
  },
  props: {
    isSubmenuList: {
      type: Boolean,
      default: false
    },
    menu: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    menuClick(item) {
      this.$emit("menu-click", item);
    },
    childActive(item) {
      this.$emit("child-active");
    }
  }
};
</script>
