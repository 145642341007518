import api from "@/api.js";

export default {
  namespaced: true,
  state() {
    return {
      doctors: []
    };
  },
  mutations: {
    setDoctors(state, payload) {
      state.doctors = payload;
    },
    addDoc(state, newDoc) {
      state.doctors.push(newDoc);
    }
  },
  actions: {
    async loadDocs(context, payload) {
      await api
        .get("doctor/")
        .then(r => {
          context.commit("setDoctors", r.data);
        })
        .catch(e => {
          // alerts.showErrorAlert(e, "Error loading Resident details");
        });
    },
    addDoc(context, newDoc) {
      context.commit("addDoc", newDoc);
    }
  },
  getters: {
    docs(state) {
      return state.doctors;
    }
  }
};
