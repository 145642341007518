import _ from "lodash";

// https://dev.to/drbragg/handling-service-worker-updates-in-your-vue-pwa-1pip
export default {
  data() {
    return {
      // refresh variables
      refreshing: false,
      registration: null,
      updateExists: false,
      debounceCheckForUpdate: _.debounce(this.swCheckForUpdate, 1000 * 60, { leading: true }) // defer checks for 1 min
    };
  },

  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener("swUpdated", this.updateAvailable, { once: true });
    document.addEventListener("swRegistered", this.swRegistered, { once: true });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      console.log(" navigator.serviceWorker.addEventListener(controllerchange");
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });

    // check for sw updates on route change, but not on inital route as its already done on page load
    setTimeout(() => {
      this.$router.afterEach(() => this.debounceCheckForUpdate());
    }, 1000 * 60);
  },

  methods: {
    // Store the SW registration so we can send it a message
    swRegistered(event) {
      this.registration = event.detail;
    },
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;

      // Let user click to refresh
      this.$buefy.snackbar.open({
        message: "An update is available.",
        type: "is-warning",
        position: "is-top",
        actionText: "Refreshing ...",
        indefinite: true
      });
      this.refreshApp();
    },

    // Called when the user accepts the update
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      console.log(this.registration);
      if (!this.registration || !this.registration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      console.log(" postMessage SKIP_WAITING");
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    swCheckForUpdate() {
      // console.log("swCheckForUpdate", this.registration, this.registration?.update);
      this.registration?.update();
    }
  }
};
