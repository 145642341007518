import Vue from "vue";
import Vuex from "vuex";
import { createStore } from "vuex-extensions";
import each from "lodash/each";
import authModule from "./modules/auth/index.js";
import orgsModule from "./modules/organisations/organisations.js";
import docsModule from "./modules/doctors/doctors.js";
import pharmacyModule from "./modules/pharmacy";
import lookupModule from "./modules/lookups/lookups.js";
import residentModule from "./modules/resident/resident";
import locationsModule from "./modules/locations/locations";
import auditTypesModule from "./modules/auditTypes";
import searchModule from "./search";
import risksModule from "./risks";
import controlsModule from "./controls";
import rolesModule from "./modules/roles";
import orgConfigModule from "./modules/orgconfig";

Vue.use(Vuex);

export default createStore(Vuex.Store, {
  modules: {
    auth: authModule,
    organisations: orgsModule,
    doctors: docsModule,
    pharmacies: pharmacyModule,
    lookups: lookupModule,
    resident: residentModule,
    locations: locationsModule,
    auditTypes: auditTypesModule,
    search: searchModule,
    risks: risksModule,
    roles: rolesModule,
    controls: controlsModule,
    orgconfig: orgConfigModule
  },
  state: {
    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    /* FooterBar */
    isStickyRatingVisible: false,

    /* Aside */
    isAsideVisible: true,
    isAsideMobileExpanded: false,

    previousPage: null
  },
  mutations: {
    /* Full Page mode */
    fullPage(state, payload) {
      state.isNavBarVisible = !payload;
      state.isAsideVisible = !payload;
      state.isFooterBarVisible = !payload;

      each(["has-aside-left", "has-navbar-fixed-top"], htmlClass => {
        if (payload) {
          document.documentElement.classList.remove(htmlClass);
        } else {
          document.documentElement.classList.add(htmlClass);
        }
      });
    },
    hideFooter(state, hide) {
      state.isFooterBarVisible = !hide;
    },
    setPreviousPage(state, page) {
      state.previousPage = page;
    },

    /* Aside Desktop */
    asideStateToggle(state, payload = null) {
      const htmlAsideClassName = "has-aside-expanded";

      let isExpand;

      if (payload !== null) {
        isExpand = payload;
      } else {
        isExpand = !state.isAsideExpanded;
      }

      if (isExpand) {
        document.documentElement.classList.add(htmlAsideClassName);
      } else {
        document.documentElement.classList.remove(htmlAsideClassName);
      }

      state.isAsideExpanded = isExpand;
    },

    /* Aside Mobile */
    asideMobileStateToggle(state, payload = null) {
      const htmlClassName = "has-aside-mobile-expanded";

      let isShow;

      if (payload !== null) {
        isShow = payload;
      } else {
        isShow = !state.isAsideMobileExpanded;
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName);
      } else {
        document.documentElement.classList.remove(htmlClassName);
      }

      state.isAsideMobileExpanded = isShow;
    },

    /* Aside Right */
    asideRightToggle(state, payload = null) {
      const htmlClassName = "has-aside-right";

      let isShow;

      if (payload !== null) {
        isShow = payload;
      } else {
        isShow = !state.isAsideRightVisible;
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName);
      } else {
        document.documentElement.classList.remove(htmlClassName);
      }

      state.isAsideRightVisible = isShow;
    },
    setStickyRatingVisible(state, visible) {
      state.isStickyRatingVisible = visible;
    }
  },
  actions: {
    setStickyRatingVisible(context, payload) {
      context.commit("setStickyRatingVisible", payload);
    }
  }
});
