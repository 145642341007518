<template>
  <div id="app" class="page-wrapper">
    <div class="content-wrapper">
      <nav-bar />
      <aside-menu :menu="menu" />
      <router-view />
    </div>
    <footer-bar />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar";
import AsideMenu from "@/components/AsideMenu";
import FooterBar from "@/components/FooterBar";
import OfflineDialog from "@/components/dialogs/OfflineDialog";

import { mapGetters } from "vuex";
import update from "./mixins/updateApplication";
import IconsMixin from "@/mixins/iconsMixin";

import PermissionsEnum from "@/enums/permissions";

export default {
  components: {
    FooterBar,
    AsideMenu,
    NavBar
  },
  data() {
    return {
      offlineMessage: null
    };
  },
  computed: {
    menu() {
      if (!this.isAuthenticated) return [];

      const adminOptions = [PermissionsEnum.AdminOrganisations, PermissionsEnum.AdminLocations, PermissionsEnum.AdminUsers];

      const residentsSubmenu = [
        // this.menuItem(PermissionsEnum.Residents, "assessments2", "file-account-outline", "Assessments"),
        this.menuItem(PermissionsEnum.Residents, "assessments", "file-account-outline", "Assessments"),
        this.menuItem(PermissionsEnum.Residents, "actions", "gesture-tap", "Actions"),
        this.menuItem(PermissionsEnum.Residents, "observations", "eye-check", "Observations"),
        this.menuItem(PermissionsEnum.Residents, "wounds", IconsMixin.icons.wound, "Wounds")
      ];

      // prettier-ignore
      var fullMenu = [
        [
          this.menuItem(PermissionsEnum.Residents, "residents", "account-multiple", "Residents", null, residentsSubmenu),

          // this.menuItem(PermissionsEnum.MenuDashboard, "dashboard", "desktop-mac", "Dashboard"),
          this.hasPermission(PermissionsEnum.KPIReports)
            ? this.menuItem(PermissionsEnum.KPIReports, "kpireports", "chart-line", "KPI Reports", null, [
              this.menuItem(PermissionsEnum.KPIEntry, "kpi", "file-chart", "KPI Data"),
              this.menuItem(PermissionsEnum.KPITest, "kpitest", "chart-areaspline-variant", "Compliance", true)
            ])
            : this.menuItem(PermissionsEnum.KPIEntry, "kpi", "file-chart", "KPI Data"),

          // this.menuItem(PermissionsEnum.AdminOrganisations, "orgs", "domain", "Organisations"),

          this.menuItem(PermissionsEnum.Risks, "risks", "shield-alert", "Risks", null, [
            this.menuItem(PermissionsEnum.Risks, "riskactions", "gesture-tap", "Risk Actions")
          ])
        ],
        this.menuSection(adminOptions, "Admin", this.menuAdminSection())
      ];

      // if only one item in main menu and has child menu, then default to expand it
      var mainMenu = fullMenu[0].filter(m => !!m); // filter out nulls
      if (mainMenu.length === 1 && mainMenu[0].menu) {
        mainMenu[0].defaultOpen = true;
      }
      fullMenu[0] = mainMenu;

      return fullMenu;
    },
    ...mapGetters(["isAuthenticated", "hasPermission"])
  },
  mixins: [update],
  methods: {
    menuItem(permission, to, icon, label, updateMark, subMenu) {
      return this.hasPermission(permission) ? { to, icon, label, updateMark, menu: subMenu } : null;
    },
    menuSection(permissions, sectionName, menu) {
      if (!this.hasPermission(permissions)) return [];
      return {
        sectionName: sectionName,
        menu: menu
      };
    },
    menuItems(permissions, menu) {
      return this.hasPermission(permissions) ? menu : [];
    },
    menuAdminSection() {
      return [
        this.menuItem(PermissionsEnum.AdminLocations, "locations", "map-marker", "Locations"),
        this.menuItem(PermissionsEnum.AdminLocations, "beds", IconsMixin.icons.bed, "Beds"),
        this.menuItem(PermissionsEnum.AdminUsers, "users", "account-multiple-outline", "Users"),
        this.menuItem(PermissionsEnum.AdminCarePlans, "careplans", "notebook-multiple", "Care Plans"),
        this.menuItem(PermissionsEnum.AdminOrgControls, "orgcontrols", "gamepad", "Controls"),
        this.menuItem(PermissionsEnum.AdminOrgConfig, "orgconfig", "cog", "Configuration"),
        ...this.menuItems(PermissionsEnum.AdminOrganisations, this.menuAdminMainSection())
      ];
    },
    menuAdminMainSection() {
      return [
        // { to: "tables", icon: "table", label: "Tables", updateMark: true },
        // { to: "forms", icon: "square-edit-outline", label: "Forms" },
        // { to: "profile", icon: "account-circle", label: "Profile" },

        {
          label: "Submenus",
          subLabel: "Submenus Example",
          icon: "view-list",
          menu: [
            { href: "#void", label: "Sub-item One" },
            { href: "#void", label: "Sub-item Two" }
          ]
        }
      ];
    },
    offline() {
      this.offlineMessage = this.$buefy.modal.open({
        parent: this,
        component: OfflineDialog,
        canCancel: [],
        hasModalCard: true,
        trapFocus: true
      });
    },
    online() {
      if (this.offlineMessage) {
        this.offlineMessage.close();
        this.offlineMessage = null;
      }
    }
  },
  mounted() {
    window.addEventListener("online", this.online);
    window.addEventListener("offline", this.offline);
    if (navigator.onLine === false) this.offline();
  },
  beforeDestroy() {
    window.removeEventListener("online", this.online);
    window.removeEventListener("offline", this.offline);
  }
};
</script>

<style scoped>
/* To get Sticky footer */
.page-wrapper {
  display: flex;
  min-height: calc(100vh - 52px);
  flex-direction: column;
}
.content-wrapper {
  flex: 1;
}
</style>
