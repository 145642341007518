import { required, confirmed, length, email, between } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("minmax", {
  params: ["min", "max"],
  validate(value, { min, max }) {
    return value >= min && value <= max;
  },
  message: "This field must be between the min and max values"
});

extend("required", {
  ...required,
  message: "This field is required"
});

extend("email", {
  ...email,
  message: "This field must be a valid email"
});

extend("confirmed", {
  ...confirmed,
  message: "This field confirmation does not match"
});

extend("length", {
  ...length,
  message: "This field must have 2 options"
});

extend("heightRequired", {
  ...required,
  message: "Click the icon to record this resident's height"
});
extend("weightRequired", {
  ...required,
  message: "Click the icon to record this resident's weight"
});
extend("height", {
  ...between,
  message: "Ensure you enter a valid height in centimetres"
});
extend("weight", {
  ...between,
  message: "Ensure you enter a valid weight in kilograms"
});
extend("weightDays", {
  validate(_, args) {
    // 0 -> Days Since weight, 1->limit
    return args[0] <= args[1];
  },
  message: "A more recent weight must be recorded"
});
extend("notBoth", {
  params: ["target"],
  validate(value, { target }) {
    return !(value && target);
  },
  message: "You cannot skip both these sections"
});
extend("actionNotesRequired", {
  ...required,
  message: "Please enter some notes to explain why this action was not done"
});
extend("bodyLocationRequired", {
  ...required,
  validate(_, args) {
    // bit of hack, args.allowFalse gives us the value passed in
    return args.allowFalse > 0;
  },
  message: "Please click on the body image to indicate the location"
});
extend("healingFactorRequired", {
  ...required,
  validate(_, args) {
    // bit of hack, args.allowFalse gives us the value passed in
    return args.allowFalse > 0;
  },
  message: "Please add at least one Factor delaying or influencing healing"
});
