import api from "@/api.js";
import OrgConfig from "@/enums/orgconfig";
import alerts from "@/common/alerts";

const defaults = new Array(Object.keys(OrgConfig.Enum).length);
for (var i = 0; i < defaults.length; i++) {
  defaults[i] = { type: i, value: null, value2: null, value3: null };
}

export default {
  namespaced: true,
  state() {
    return {
      defaults: defaults
    };
  },
  mutations: {
    setDefaults(state, newDefaults) {
      state.defaults.forEach((d, i) => {
        if (!newDefaults[i]) newDefaults[i] = state.defaults[i];
      });
      state.defaults = newDefaults;
    }
  },
  actions: {
    async setDefaults(context, data) {
      context.commit("setDefaults", data);
    },
    loadConfig(context, orgId) {
      api
        .get("orgConfig/" + orgId)
        .then(r => {
          if (r.data) context.commit("setDefaults", r.data);
        })
        .catch(e => alerts.showErrorAlert(e, "Failed to load Audit Types"));
    }
  },
  getters: {
    defaults(state) {
      return state.defaults;
    }
  }
};
